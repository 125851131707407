import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCy7jvJVOi_3BSpf3znmYRu6dnZNrsKR0c",
  authDomain: "worship-cifras-v4bbg4.firebaseapp.com",
  projectId: "worship-cifras-v4bbg4",
  storageBucket: "worship-cifras-v4bbg4.appspot.com",
  messagingSenderId: "107462009076094754054",
  appId: "311303303907",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };
