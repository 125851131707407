import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, storage } from '../../../firebase';
import { updateProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

// Importações para redimensionamento e compressão de imagens
import imageCompression from 'browser-image-compression';

// Importações para o Firebase Storage
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AccountDetail = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [loading, setLoading] = useState(true);

  // Campos do formulário
  const [name, setName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [nationality, setNationality] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [shortBio, setShortBio] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setProfileImage(data.image_url || '');
          // Inicializa os campos do formulário
          setName(data.name || '');
          setBirthDate(data.birth_date || '');
          setCurrentLocation(data.current_location || '');
          setNationality(data.nationality || '');
          setPhoneNumber(data.phone_number || '');
          setShortBio(data.short_bio || '');
        }
      }
      setLoading(false);
    };
    fetchUserData();
  }, []);

  const handleBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        alert('Por favor, selecione um arquivo de imagem.');
        return;
      }
      if (file.size > 10 * 1024 * 1024) {
        alert('A imagem deve ter no máximo 10MB.');
        return;
      }
  
      try {
        // Configurações para compressão e redimensionamento
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
  
        // Cria uma pré-visualização da imagem
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(compressedFile);
  
        // Define o arquivo comprimido no estado
        setProfileImageFile(compressedFile);
  
      } catch (error) {
        console.error('Erro ao processar a imagem:', error);
      }
    }
  };

  const handleSave = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        let imageUrl = profileImage; // Usa o URL atual por padrão
        // Se uma nova imagem foi selecionada, faz o upload
        if (profileImageFile) {
          const storageRef = ref(storage, `users/profile/${user.uid}.jpg`);
          await uploadBytes(storageRef, profileImageFile);
          imageUrl = await getDownloadURL(storageRef);
        }

        // Atualiza o perfil do usuário no Firebase Auth
        await updateProfile(user, {
          displayName: name,
          photoURL: imageUrl,
        });

        // Atualiza o documento do usuário no Firestore
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          name: name,
          birth_date: birthDate,
          current_location: currentLocation,
          nationality: nationality,
          phone_number: phoneNumber,
          short_bio: shortBio,
          image_url: imageUrl,
        });

        alert('Dados atualizados com sucesso.');
        navigate(-1); // Volta para a página anterior

      } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        alert('Ocorreu um erro ao salvar os dados.');
      }
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Barra de Navegação */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Voltar
        </Typography>
      </Box>

      {loading ? (
        <Typography>Carregando...</Typography>
      ) : (
        <Box>
          {/* Foto do Perfil */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 3 }}>
            <Avatar
              src={previewImage || profileImage || '../../assets/images/profile/profile-default.png'}
              alt="Foto do Usuário"
              sx={{ width: 120, height: 120 }}
            />
            <Button
              variant="contained"
              component="label"
              startIcon={<CameraAltIcon />}
              sx={{ marginTop: 2 }}
            >
              Alterar Foto
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleImageChange}
              />
            </Button>
          </Box>

          {/* Campos do Formulário */}
          <Stack spacing={2}>
            <TextField
              label="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Data de Nascimento"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              fullWidth
            />
            <TextField
              label="Localização Atual"
              value={currentLocation}
              onChange={(e) => setCurrentLocation(e.target.value)}
              fullWidth
            />
            <TextField
              label="Nacionalidade"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              fullWidth
            />
            <TextField
              label="Número de Telefone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
            />
            <TextField
              label="Biografia Curta"
              value={shortBio}
              onChange={(e) => setShortBio(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Stack>

          {/* Botão para Salvar as Alterações */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginTop: 3 }}
          >
            Salvar Alterações
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AccountDetail;
