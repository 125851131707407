// TopBar.js
import React, { useEffect, useState } from 'react';
import { Box, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'; // Importar o auth do Firebase
import { onAuthStateChanged } from 'firebase/auth'; // Importar onAuthStateChanged
import LogoImage from '../../assets/images/logo/logo-light-beta.png';
import AvatarImage from '../../assets/images/profile/profile-default.png';

const TopBar = () => {
  const navigate = useNavigate();
  const [userPhoto, setUserPhoto] = useState(AvatarImage);

  const handleAvatarClick = () => {
    navigate('/account'); // Navega para a rota '/account'
  };

  useEffect(() => {
    // Escuta as mudanças no estado de autenticação
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.photoURL) {
        setUserPhoto(user.photoURL);
      } else {
        setUserPhoto(AvatarImage);
      }
    });

    // Limpa o listener quando o componente for desmontado
    return () => unsubscribe();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Avatar
        alt="Usuário"
        src={userPhoto}
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
        onClick={handleAvatarClick}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Box component="img" src={LogoImage} alt="Logo" sx={{ height: 40 }} />
    </Box>
  );
};

export default TopBar;
