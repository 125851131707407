// transposeChords.js

// Função para realizar a transposição
export const transposeChords = (chords, originalTone, newTone) => {
  chords = chords.replace(/\\n/g, '\n');

  const majorTones = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  const minorTones = majorTones.map(tone => tone + 'm');
  const allTones = [...majorTones, ...minorTones];

  const toneAliases = {
    'Db': 'C#',
    'Eb': 'D#',
    'Gb': 'F#',
    'Ab': 'G#',
    'Bb': 'A#',
    'Dbm': 'C#m',
    'Ebm': 'D#m',
    'Gbm': 'F#m',
    'Abm': 'G#m',
    'Bbm': 'A#m',
  };

  const normalizeTone = (tone) => {
    if (allTones.includes(tone)) return tone;
    return toneAliases[tone] || null;
  };

  const findToneIndex = (tone) => allTones.indexOf(tone);

  const originalToneNormalized = normalizeTone(originalTone);
  const newToneNormalized = normalizeTone(newTone);

  if (originalToneNormalized === null || newToneNormalized === null) {
    console.log('Tom inválido');
    return chords; // Tom inválido
  }

  const originalToneIndex = findToneIndex(originalToneNormalized);
  const newToneIndex = findToneIndex(newToneNormalized);

  const semitoneShift = newToneIndex - originalToneIndex;

  // Tipos de acordes conhecidos (sem o 'm')
  const chordTypes = [
    'maj', 'min', 'dim', 'aug',
    'sus', 'sus2', 'sus4',
    'add9', 'add11', 'add13',
    '7M', 'M7', '4', '7', '9', '11', '13', '6', '5', '2',
    '°', 'ø', '\\+', '-', // '+' precisa ser escapado
  ];

  const chordTypeRegex = chordTypes.join('|');

  // Regex para identificar acordes
  const chordRegex = new RegExp(
    '^' +
    '([A-G][#b]?m?)' +          // Base do acorde, incluindo 'm' para menor
    '(?:(' + chordTypeRegex + '))?' + // Tipo de acorde opcional
    '(?:\/([A-G][#b]?m?))?' +   // Inversão opcional, incluindo 'm' para notas menores
    '$', 'i'
  );

  const chordRegexGlobal = new RegExp(
    '([A-G][#b]?m?(?:(' + chordTypeRegex + '))?(?:\/[A-G][#b]?m?)?)',
    'g'
  );

  // Função para normalizar um acorde, separando a base, extensão e nota do baixo
  const normalizeChord = (chord) => {
    const match = chord.match(chordRegex);
    if (!match) return null;
    const baseChord = match[1];
    const extension = match[2] || '';
    const bassNote = match[3]; // Pode ser undefined se não houver inversão
    return { baseChord, extension, bassNote };
  };

  const isMinorChord = (normalizedChord) => {
    return normalizedChord.baseChord.endsWith('m');
  };

  const isMinorNote = (note) => {
    return note.endsWith('m');
  };

  const transposeChord = (chord) => {
    const normalized = normalizeChord(chord);
    if (!normalized) return chord; // Não é um acorde válido
    const { baseChord, extension, bassNote } = normalized;

    // Transpor a base do acorde
    const baseChordNormalized = normalizeTone(baseChord);
    const baseChordIndex = findToneIndex(baseChordNormalized);

    if (baseChordIndex === -1) return chord; // Acorde não encontrado

    const newBaseChordIndex = (baseChordIndex + semitoneShift + allTones.length) % allTones.length;
    let newBaseChord = allTones[newBaseChordIndex];

    // Preservar se é menor ou maior
    if (isMinorChord(normalized)) {
      if (!newBaseChord.endsWith('m')) {
        newBaseChord += 'm';
      }
    } else {
      if (newBaseChord.endsWith('m')) {
        newBaseChord = newBaseChord.slice(0, -1);
      }
    }

    // Transpor a nota do baixo, se houver
    let newBassNote = '';
    if (bassNote) {
      const isMinorBassNote = isMinorNote(bassNote);
      const bassNoteNormalized = normalizeTone(bassNote);
      const bassNoteIndex = findToneIndex(bassNoteNormalized);

      if (bassNoteIndex !== -1) {
        const newBassNoteIndex = (bassNoteIndex + semitoneShift + allTones.length) % allTones.length;
        let newBassNoteBase = allTones[newBassNoteIndex];

        // Preservar se a nota do baixo é menor
        if (isMinorBassNote) {
          if (!newBassNoteBase.endsWith('m')) {
            newBassNoteBase += 'm';
          }
        } else {
          if (newBassNoteBase.endsWith('m')) {
            newBassNoteBase = newBassNoteBase.slice(0, -1);
          }
        }

        newBassNote = newBassNoteBase;
      } else {
        newBassNote = bassNote; // Nota do baixo não encontrada, mantém original
      }
    }

    // Construir o acorde transposto
    let transposedChord = newBaseChord + extension;
    if (newBassNote) {
      transposedChord += '/' + newBassNote;
    }

    return transposedChord;
  };

  // Função para determinar se uma linha é uma linha de acordes
  const isChordLine = (line) => {
    const words = line.trim().split(/\s+/).filter(word => word.length > 0);
    if (words.length === 0) return false; // Linha vazia

    return words.every(word => chordRegex.test(word));
  };

  const lines = chords.split('\n');

  const transposedLines = lines.map((line) => {
    if (isChordLine(line)) {
      // Transpor apenas os acordes na linha
      return line.replace(chordRegexGlobal, (match) => transposeChord(match));
    } else {
      // Linha de letra, não transpor
      return line;
    }
  });

  return transposedLines.join('\n');
};
