// CardSection.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import CardSingleItem from './CardSingleItem';

const CardSection = ({ title, type, data, itemType }) => {
  return (
    <Box sx={{ paddingY: 2 }}>
      <Typography variant="h6" sx={{ paddingX: 2, paddingY: 2, fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          paddingX: 2,
          gap: 0,
          flexWrap: 'nowrap',
          alignItems: 'center',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {data.map((item, index) => (
          <CardSingleItem key={item.id || index} item={item} type={type} itemType={itemType} />
        ))}
      </Box>
    </Box>
  );
};

export default CardSection;
