import React from 'react';
import { Box, Typography } from '@mui/material';

const gradients = [
  // Gradiente vermelho escuro
  'linear-gradient(135deg, #D1495B 0%, #9A1F40 100%)',
  
  // Gradiente rosa escuro
  'linear-gradient(135deg, #D76D77 0%, #BA3A66 100%)',
  
  // Gradiente azul escuro
  'linear-gradient(135deg, #325288 0%, #2A4365 100%)',
  
  // Gradiente verde escuro
  'linear-gradient(135deg, #3D7068 0%, #275D47 100%)',
  
  // Gradiente roxo escuro
  'linear-gradient(135deg, #6A0572 0%, #4A025E 100%)',
  
  // Gradiente amarelo escuro
  'linear-gradient(135deg, #F4A261 0%, #E76F51 100%)',
  
  // Gradiente laranja escuro
  'linear-gradient(135deg, #FF7F51 0%, #D65A31 100%)',
  
  // Gradiente lavanda escuro
  'linear-gradient(135deg, #5D4E8C 0%, #3E3174 100%)',
];


const CategoryCard = ({ title, index }) => {
  const gradient = gradients[index % gradients.length];

  return (
    <Box
      sx={{
        height: 70,
        borderRadius: 2,
        background: gradient,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.9,
        },
      }}
    >
      <Typography variant="subtitle1" sx={{ color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
        {title}
      </Typography>
    </Box>
  );
};

export default CategoryCard;
