// formatSong.js
import React from 'react';

// Função para verificar se uma palavra é um acorde válido
const isValidChord = (word) => {
  const regex = new RegExp(
    '^' +
    '[A-G]' +                // Nota raiz
    '([#b])?' +              // Opcional sustenido ou bemol
    '(maj|min|dim|aug|sus[2|4]?|add[0-9]+|m|M|7M)?' + // Qualificador de acorde
    '([0-9]+)?' +            // Número opcional
    '(\\/[A-G]([#b])?)?' +   // Inversão de baixo opcional
    '$',
    'i' // Flag para case-insensitive
  );
  return regex.test(word);
};

// Função para verificar se a linha contém acordes ou letras
const isChordLine = (line) => {
  const words = line.split(' ').filter(word => word.trim().length > 0);
  return words.every(word => isValidChord(word));
};

// Função para formatar a música e retornar componentes de acordes e letras
export const formatSong = (text, onChordClick = () => {}) => {
  const formattedText = text.replace(/\\n/g, '\n'); // Substitui '\\n' por '\n'

  const blocks = formattedText.split('\n\n');

  const chordsComponent = [];
  const lyricsComponent = [];

  blocks.forEach((block, blockIndex) => {
    const lines = block.split('\n');

    const chordsBlock = [];
    const lyricsBlock = [];

    lines.forEach((line, lineIndex) => {
      const trimmedLine = line.trim();

      // Identifica partes como [Intro], [Verso], [Refrão], etc., e estiliza-as em cinza
      if (trimmedLine.startsWith('[') && trimmedLine.endsWith(']')) {
        const partComponent = (
          <p key={`${blockIndex}-${lineIndex}`} style={{ color: 'gray', margin: '5px 0' }}>
            {trimmedLine}
          </p>
        );
        chordsBlock.push(partComponent);
        lyricsBlock.push(partComponent);
      } else if (isChordLine(trimmedLine)) {
        // Linha de acordes
        const chords = trimmedLine.split(' ');
        const chordsLineComponent = (
          <p key={`${blockIndex}-${lineIndex}`} style={{ margin: '5px 0' }}>
            {chords.map((chord, idx) => (
              <span
                key={idx}
                style={{ color: '#A52422', cursor: 'pointer' }}
                onClick={() => onChordClick(chord)}
              >
                {chord}{' '}
              </span>
            ))}
          </p>
        );
        chordsBlock.push(chordsLineComponent);
        // Na letra, não adicionamos nada para linhas de acordes
      } else {
        // Linha de letra
        const words = line.split(' ');

        const lyricsLineComponent = (
          <p key={`${blockIndex}-${lineIndex}`} style={{ margin: '5px 0' }}>
            {words.map((word, idx) => {
              const wordLower = word.toLowerCase();

              if ((wordLower === 'em' || wordLower === 'a' || wordLower === 'e') && trimmedLine.length > 8) {
                return <span key={idx} style={{ color: 'white' }}>{word} </span>;
              }

              // Se a palavra for um acorde, ignoramos na letra
              if (isValidChord(word)) {
                return null;
              } else {
                // Tratar como letra
                return <span key={idx} style={{ color: 'white' }}>{word} </span>;
              }
            })}
          </p>
        );

        // Para a cifra, exibimos a linha com acordes em vermelho e letras em branco
        const chordsLineComponent = (
          <p key={`${blockIndex}-${lineIndex}`} style={{ margin: '5px 0' }}>
            {words.map((word, idx) => {
              const wordLower = word.toLowerCase();

              // Regra específica para "Em", "A", "E" quando em linhas de letras
              if ((wordLower === 'em' || wordLower === 'a' || wordLower === 'e') && trimmedLine.length > 8) {
                return <span key={idx} style={{ color: 'white' }}>{word} </span>;
              }

              if (isValidChord(word)) {
                return (
                  <span
                    key={idx}
                    style={{ color: '#A52422', cursor: 'pointer' }}
                    onClick={() => onChordClick(word)}
                  >
                    {word}{' '}
                  </span>
                );
              } else {
                return <span key={idx} style={{ color: 'white' }}>{word} </span>;
              }
            })}
          </p>
        );

        chordsBlock.push(chordsLineComponent);
        lyricsBlock.push(lyricsLineComponent);
      }
    });

    // Adicionar o bloco aos componentes somente se houver conteúdo
    if (chordsBlock.length > 0) {
      chordsComponent.push(
        <div key={`chords-block-${blockIndex}`} style={{ marginBottom: '15px' }}>
          {chordsBlock}
        </div>
      );
    }

    if (lyricsBlock.length > 0) {
      lyricsComponent.push(
        <div key={`lyrics-block-${blockIndex}`} style={{ marginBottom: '15px' }}>
          {lyricsBlock}
        </div>
      );
    }
  });

  return { chordsComponent, lyricsComponent };
};
