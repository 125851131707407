// App.js
import React from 'react';
// Importando fontes Roboto
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Importando react-router-dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Importando componentes
import SignIn from './components/account/sign-in/SignIn';
import SignUp from './components/account/sign-up/SignUp';
import Home from './components/Home';
import Search from './components/search/Search';
import Song from './components/song/Song';
import ArtistDetails from './components/ArtistDetails';
import Settings from './components/settings/Settings';
import AccountDetail from './components/account/details/AccountDetail';
import CreateArtist from './components/artist/CreateArtist';
// Importando tema
import AppTheme from './assets/shared-theme/AppTheme';
// Importando AuthProvider e PrivateRoute
import { AuthProvider } from './components/auth/AuthContext';
import PrivateRoute from './components/auth/PrivateRoute';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppTheme>
          <Routes>
            {/* Rotas públicas */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            {/* Rotas protegidas */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/search"
              element={
                <PrivateRoute>
                  <Search />
                </PrivateRoute>
              }
            />
            <Route
              path="/song/:id"
              element={
                <PrivateRoute>
                  <Song />
                </PrivateRoute>
              }
            />
            <Route
              path="/artist/:id"
              element={
                <PrivateRoute>
                  <ArtistDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/create/artist"
              element={
                <PrivateRoute>
                  <CreateArtist />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <AccountDetail />
                </PrivateRoute>
              }
            />
          </Routes>
        </AppTheme>
      </AuthProvider>
    </Router>
  );
};

export default App;
