import React from 'react';
import styled from 'styled-components';

const ArtistDetailsContainer = styled.div`
  padding: 20px;
`;

const ArtistDetails = () => {
  return (
    <ArtistDetailsContainer>
      <h1>Detalhes do Artista</h1>
      {/* Adicione descrição e músicas do artista */}
    </ArtistDetailsContainer>
  );
};

export default ArtistDetails;
