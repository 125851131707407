// Settings.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TopBar from '../common/TopBar';
import FooterNavbar from '../common/FooterNavbar';
import { sendEmail } from './utils/sendEmail';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

const buttonStyle = {
  backgroundColor: '#A52422',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#FF5722',
  },
};

const Settings = () => {
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = false; // Você pode ajustar conforme necessário

  const handleOpenContactModal = () => setOpenContactModal(true);
  const handleCloseContactModal = () => setOpenContactModal(false);

  const handleOpenTermsModal = () => setOpenTermsModal(true);
  const handleCloseTermsModal = () => setOpenTermsModal(false);

  const handleOpenLogoutModal = () => setOpenLogoutModal(true);
  const handleCloseLogoutModal = () => setOpenLogoutModal(false);

  const handleSendContact = () => {
    if (message.length >= 50) {
      sendEmail({
        subject: `${description}`,
        message,
        to: 'worshipcifrasbr@gmail.com',
        cc: 'gustavo.honorio@yahoo.com.br',
      });
      setOpenContactModal(false);
    } else {
      alert('A mensagem precisa ter pelo menos 50 caracteres.');
    }
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/signin');
    });
  };

  return (
    <Box>
      <TopBar title="Configurações" />

      {/* Menu da tela */}
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Configurações</Typography>

        {/* "Fale Conosco" Menu */}
        <Box onClick={handleOpenContactModal} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1">Fale Conosco</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />

        {/* "Termos e Condições" Menu */}
        <Box onClick={handleOpenTermsModal} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1">Termos e Condições</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />

        {/* "Sair" Menu */}
        <Box onClick={handleOpenLogoutModal} sx={{ padding: 2, cursor: 'pointer' }}>
          <Typography variant="body1" color="error">Sair</Typography>
        </Box>
        <Divider sx={{ backgroundColor: 'gray' }} />
      </Box>

      {/* Dialog Fale Conosco */}
      <Dialog
        open={openContactModal}
        onClose={handleCloseContactModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#fff',
            borderRadius: '10px',
            maxHeight: 'calc(100% - 32px)',
          },
        }}
      >
        <DialogTitle>Fale Conosco</DialogTitle>
        <DialogContent dividers style={{ overflowY: 'auto' }}>
          <TextField
            label="Descrição resumida"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Queremos te ouvir"
            fullWidth
            multiline
            rows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={buttonStyle} onClick={handleSendContact}>Enviar</Button>
          <Button onClick={handleCloseContactModal} color="primary">Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Termos e Condições */}
      <Dialog
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#fff',
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle>Termos e Condições de uso do Worship Cifras</DialogTitle>
        <DialogContent dividers style={{ overflowY: 'auto' }}>
          <Typography variant="body1">
            1. Aceitação dos Termos

            Ao baixar, acessar ou utilizar o aplicativo Worship Cifras ("Aplicativo"), você concorda em cumprir e estar vinculado a estes Termos e Condições de Uso ("Termos"). Se você não concorda com estes Termos, não utilize o Aplicativo.
                
            2. Uso do Aplicativo
                
            O Worship Cifras é destinado ao consumo pessoal e não comercial de cifras musicais gospel. Você concorda em utilizar o Aplicativo somente para fins legais e de acordo com estes Termos e todas as leis, regras e regulamentos aplicáveis.
                
            3. Propriedade Intelectual
                
            Todos os conteúdos presentes no Aplicativo, incluindo, mas não se limitando a, textos, gráficos, logos, ícones, imagens, clipes de áudio, downloads digitais e compilações de dados, são propriedade exclusiva do Worship Cifras ou de seus licenciantes e estão protegidos pelas leis de direitos autorais e outras leis de propriedade intelectual.
                
            4. Conduta do Usuário
                
            4.1 Você concorda em não utilizar o Aplicativo para qualquer finalidade ilegal ou não autorizada.
            4.2 É proibido transmitir qualquer vírus ou código de natureza destrutiva.
            4.3 Você não deve tentar obter acesso não autorizado a qualquer parte do Aplicativo ou de seus sistemas relacionados.
            5. Limitação de Responsabilidade
                
            5.1 O Worship Cifras não garante que o Aplicativo estará disponível de forma ininterrupta ou livre de erros.
            5.2 Em nenhuma circunstância o Worship Cifras será responsável por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais resultantes do uso ou da incapacidade de usar o Aplicativo.
            6. Modificações dos Termos
                
            Reservamo-nos o direito de modificar ou atualizar estes Termos a qualquer momento, sem aviso prévio. As alterações entrarão em vigor imediatamente após a publicação no Aplicativo. É sua responsabilidade revisar periodicamente estes Termos.
                
            7. Rescisão
                
            Podemos suspender ou encerrar seu acesso ao Aplicativo a qualquer momento, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, mas não se limitando, à violação destes Termos.
                
            8. Privacidade
                
            O uso do Aplicativo também é regido por nossa Política de Privacidade, que está incorporada a estes Termos por esta referência.
                
            9. Lei Aplicável
                
            Estes Termos serão regidos e interpretados de acordo com as leis do Brasil, sem considerar seus conflitos de provisões legais.
                
            10. Contato
                
            Se você tiver quaisquer dúvidas ou preocupações sobre estes Termos, entre em contato conosco através do e-mail: worshipcifrasbr@gmail.com .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTermsModal} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de Logout */}
      <Dialog
        open={openLogoutModal}
        onClose={handleCloseLogoutModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#fff',
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle>Deseja sair?</DialogTitle>
        <DialogActions>
          <Button sx={buttonStyle} onClick={handleLogout}>Sim</Button>
          <Button onClick={handleCloseLogoutModal} color="primary">Cancelar</Button>
        </DialogActions>
      </Dialog>

      <FooterNavbar />
    </Box>
  );
};

export default Settings;
