// Home.js
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import TopBar from './common/TopBar';
import CardSection from './common/CardSection';
import FooterNavbar from './common/FooterNavbar';
import { collection, query, orderBy, limit, getDocs, getFirestore } from 'firebase/firestore';

const Home = () => {
  const [mostSearchedSongs, setMostSearchedSongs] = useState([]);
  const [newsSongs, setNewsSongs] = useState([]);
  const [trendingArtists, setTrendingArtists] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    // Fetch "Músicas mais buscadas"
    const fetchMostSearchedSongs = async () => {
      try {
        const songsRef = collection(db, 'songs');
        const q = query(songsRef, orderBy('searches_count', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        const songs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setMostSearchedSongs(songs);
      } catch (error) {
        console.error('Error fetching most searched songs:', error);
      }
    };

    // Fetch "Novidades"
    const fetchNewsSongs = async () => {
      try {
        const songsRef = collection(db, 'songs');
        const q = query(songsRef, orderBy('created', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        const songs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setNewsSongs(songs);
      } catch (error) {
        console.error('Error fetching news songs:', error);
      }
    };

    // Fetch "Artistas em alta"
    const fetchTrendingArtists = async () => {
      try {
        const artistsRef = collection(db, 'artists');
        const q = query(artistsRef, orderBy('created', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        const artists = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTrendingArtists(artists);
      } catch (error) {
        console.error('Error fetching trending artists:', error);
      }
    };

    // Call the fetch functions
    fetchMostSearchedSongs();
    fetchNewsSongs();
    fetchTrendingArtists();
  }, []);

  return (
    <Box sx={{ pb: 7 }}> {/* Add padding bottom for the FooterNavbar */}
      <TopBar />
      <CardSection title="Músicas mais buscadas" type="square" data={mostSearchedSongs} itemType="song" />
      <CardSection title="Novidades" type="square" data={newsSongs} itemType="song" />
      <CardSection title="Artistas em alta" type="round" data={trendingArtists} itemType="artist" />
      <FooterNavbar />
    </Box>
  );
};

export default Home;
