// src/Song.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton, Avatar, Chip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Ícone de coração preenchido
import { fetchSongData } from './utils/fetchSongData';
import { formatSong } from './utils/formatSong';
import { transposeChords } from './utils/transposeChords';
import ToneSelector from './ToneSelector';
import { auth, firestore } from '../../firebase'; // Ajuste o caminho conforme necessário
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  increment,
  updateDoc,
} from 'firebase/firestore';

const Song = () => {
  const { id } = useParams(); // Pega o ID da música da URL
  const navigate = useNavigate();
  const [song, setSong] = useState(null);
  const [songContent, setSongContent] = useState(''); // Conteúdo exibido (Cifra, Letra ou Detalhes)
  const [chords, setChords] = useState(''); // Guarda os acordes originais
  const [chordsComponent, setChordsComponent] = useState(null);
  const [lyricsComponent, setLyricsComponent] = useState(null);
  const [details, setDetails] = useState(null); // Guarda os detalhes da música
  const [selectedTab, setSelectedTab] = useState('Cifra');
  const [tone, setTone] = useState(''); // Valor inicial para o select
  const [originalTone, setOriginalTone] = useState(''); // Guarda o tom original para referência

  const [liked, setLiked] = useState(false); // Estado para indicar se o usuário curtiu a música
  const [userId, setUserId] = useState(null); // Armazena o ID do usuário

  useEffect(() => {
    const loadSongData = async () => {
      try {
        const { songData, songContentData, songDetailsData } =
          await fetchSongData(id);
        setSong(songData);
        setChords(songContentData.chords); // Guarda os acordes originais
        const { chordsComponent, lyricsComponent } = formatSong(
          songContentData.chords
        );
        setChordsComponent(chordsComponent);
        setLyricsComponent(lyricsComponent);
        setSongContent(chordsComponent); // Exibe a cifra por padrão
        setTone(songContentData.tone); // Carrega o tom original no select
        setOriginalTone(songContentData.tone); // Define o tom original para a transposição
        setDetails(songDetailsData); // Define os detalhes da música
  
        // Incrementa o campo 'searches_count' da música
        const songRef = doc(firestore, `songs/${id}`);
        await updateDoc(songRef, {
          searches_count: increment(1),
        });
        
        // Incrementa o campo 'searches_count' do artista
        const artistRef = doc(firestore, `artists/${song.artist_id}`);
        await updateDoc(artistRef, {
          searches_count: increment(1),
        });
  
        // Verifica se o usuário curtiu a música
        const user = auth.currentUser;
        if (user) {
          setUserId(user.uid);
          const likedSongRef = doc(
            firestore,
            `users/${user.uid}/liked_songs/${id}`
          );
          const docSnap = await getDoc(likedSongRef);
          setLiked(docSnap.exists());
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    loadSongData();
  }, [id]);

  // Função chamada quando o usuário altera o tom
  const handleToneChange = (newTone) => {
    const transposedChords = transposeChords(chords, originalTone, newTone); // Transpõe os acordes
    setTone(newTone); // Atualiza o tom selecionado
    const { chordsComponent, lyricsComponent } = formatSong(transposedChords);
    setChordsComponent(chordsComponent);
    setLyricsComponent(lyricsComponent);
    // Atualiza o conteúdo exibido com base na aba selecionada
    if (selectedTab === 'Cifra') {
      setSongContent(chordsComponent);
    } else if (selectedTab === 'Letra') {
      setSongContent(lyricsComponent);
    }
  };

  const handleBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);

    // Alterna entre exibir 'chordsComponent', 'lyricsComponent' ou 'details'
    if (tab === 'Cifra') {
      setSongContent(chordsComponent);
    } else if (tab === 'Letra') {
      setSongContent(lyricsComponent);
    } else if (tab === 'Detalhes' && details) {
      const detailsComponent = (
        <div style={{ color: '#fff' }}>
          <p>
            <span style={{ color: 'gray' }}>Compositor:</span>{' '}
            <span style={{ color: '#fff' }}>{details.composer || 'N/A'}</span>
          </p>
          <p>
            <span style={{ color: 'gray' }}>Produtor:</span>{' '}
            <span style={{ color: '#fff' }}>{details.producer || 'N/A'}</span>
          </p>
          <p>
            <span style={{ color: 'gray' }}>Outros:</span>{' '}
            <span style={{ color: '#fff' }}>{details.other || 'N/A'}</span>
          </p>
          <p>
            <span style={{ color: 'gray' }}>Lançamento:</span>{' '}
            <span style={{ color: '#fff' }}>{details.release || 'N/A'}</span>
          </p>
        </div>
      );
      setSongContent(detailsComponent);
    }
  };

  // Função para lidar com o clique no ícone de curtir
  const handleLikeClick = async () => {
    if (!userId) {
      // Se o usuário não estiver autenticado, redireciona para a página de login
      alert('Você precisa estar logado para curtir uma música.');
      navigate('/signin');
      return;
    }

    const likedSongRef = doc(firestore, `users/${userId}/liked_songs/${id}`);
    const songRef = doc(firestore, `songs/${id}`);

    try {
      if (liked) {
        // Descurtir a música
        await deleteDoc(likedSongRef);
        await updateDoc(songRef, {
          likes_count: increment(-1),
        });
        setLiked(false);
      } else {
        // Curtir a música
        await setDoc(likedSongRef, {
          created: new Date(),
        });
        await updateDoc(songRef, {
          likes_count: increment(1),
        });
        setLiked(true);
      }
    } catch (error) {
      console.error('Erro ao atualizar o like:', error);
    }
  };

  if (!song) {
    return <div>Sua canção está sendo preparada...</div>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      {/* Barra de Navegação */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Voltar
        </Typography>
      </Box>

      {/* Informações da música */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={song.image_url}
            alt={song.name}
            variant="rounded"
            sx={{ width: 72, height: 72, marginRight: 2 }}
          />
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {song.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {song.artist_name}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={handleLikeClick}>
          {liked ? (
            <FavoriteIcon sx={{ color: '#A52422' }} />
          ) : (
            <FavoriteBorderIcon />
          )}
        </IconButton>
      </Box>

      {/* Tags para Cifra, Letra, Detalhes */}
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
        {['Cifra', 'Letra', 'Detalhes'].map((tab) => (
          <Chip
            key={tab}
            label={tab}
            clickable
            color={selectedTab === tab ? 'primary' : 'default'}
            onClick={() => handleTabClick(tab)}
            sx={{
              backgroundColor: selectedTab === tab ? '#A52422' : 'default', // Fundo vermelho escuro para tag selecionada
              color: '#fff', // Texto branco
              border: 'none', // Sem borda
              fontSize: '1rem',
              padding: '5px 10px',
              '&:hover': {
                backgroundColor:
                  selectedTab === tab ? '#A52422' : 'default', // Remove o hover azul
              },
              '&:focus': {
                outline: 'none', // Remove o contorno azul
              },
            }}
          />
        ))}
      </Box>

      {/* Select Box para Alterar o Tom (somente quando "Cifra" está selecionado) */}
      {selectedTab === 'Cifra' && (
        <ToneSelector tone={tone} setTone={handleToneChange} />
      )}

      {/* Exibição da Cifra, Letra ou Detalhes */}
      <Box
        sx={{ backgroundColor: '#2f2f2f', padding: 2, borderRadius: 2 }} // Fundo cinza escuro
      >
        <Box sx={{ whiteSpace: 'pre-line', color: '#fff' }}>
          {songContent || 'Conteúdo não encontrado.'}
        </Box>
      </Box>
    </Box>
  );
};

export default Song;
