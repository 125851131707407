import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CategoryCard from '../common/CategoryCard';

const SectionCards = ({ title, data }) => {
  return (
    <Box sx={{ paddingX: 2, marginY: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={6} key={index}>
            <CategoryCard title={item.title} index={index} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SectionCards;
