import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    return (
      <Navigate
        to="/signin"
        replace
        state={{ from: location, message: 'Por favor, faça login novamente' }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
